/* eslint-disable no-sparse-arrays */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import Loader from "react-loader-spinner";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import Switch from "react-switch";
import Container from "../../components/container.component";
import GoBack from "../../components/goback.component";
import Select from "../../components/select";
import Api from "../../services/api.service";
import {
  AssociationsNumber,
  Body,
  GeneralWrapper,
  IconColumn,
  IpohText,
  IpohTime,
  IpohWrapper,
  Leaderboard,
  LeaderboardPosition,
  LeaderboardPositionWrapper,
  LeaderboardWrapper,
  ManifestationsNumber,
  MegaphoneIcon,
  MegaphoneIconLittle,
  NextDivisor,
  Number,
  NumberIcons,
  ProfilePictureAndName,
  ProfilePlus,
  ProfilePlusLittle,
  RegisteredAssociationsText,
  RegisteredAssociationsWrapper,
  RegisteredManifestationsText,
  RegisteredManifestationsWrapper,
  SwitchText,
  SwitchWrapper,
  UserInfoTeam,
  UsernameMember,
  UserRow,
  UserRowContainer,
  Wallpaper,
} from "../home/home.style";
import Chart from "../../components/chart/chart.component";

function Teams() {
  const history = useHistory();
  const [leaderboardType, setLeaderboardType] = useState("month");
  const [chartType, setChartType] = useState("month");
  const [selectedTeam, setSelectedTeam] = useState();

  const { data: profile, isFetching: isFetchingProfile } = useQuery({
    queryKey: ["profile"],
    queryFn: async () => {
      const { data } = await Api.getProfile();
      return data;
    },
    onSuccess: (data) => {
      const [firstGroup] = data.user.groups;
      setSelectedTeam(firstGroup?.id);
    },
  });

  const { data: team, isFetching: isFetchingTeam } = useQuery({
    queryKey: ["team", selectedTeam],
    queryFn: async () => {
      const { data } = await Api.getGroup(selectedTeam);
      return data;
    },
    enabled: !!selectedTeam && !!profile,
  });

  const visualizeOne = (id) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    history.push({ pathname: "/profile", state: id });
  };

  const selectOptions = useMemo(
    () =>
      profile?.user?.groups.map((group) => ({
        label: group.name,
        value: group.id,
      })) ?? [],
    [profile?.user?.groups]
  );

  const currentOption = useMemo(
    () => selectOptions.find((option) => option.value === selectedTeam),
    [selectOptions, selectedTeam]
  );

  const chartData = useMemo(() => {
    if (!team) {
      return {
        labels: [],
        datasets: [],
      };
    }

    if (chartType === "month") {
      return {
        labels: [
          "gen",
          "feb",
          "mar",
          "apr",
          "mag",
          "giu",
          "lug",
          "ago",
          "set",
          "ott",
          "nov",
          "dic",
        ],
        datasets: [
          {
            label: "  Associazioni",
            backgroundColor: "#B40000",
            borderColor: "#B40000",
            fill: false,
            lineTension: 0,
            borderWidth: 2,
            pointBorderWidth: 6,
            pointHoverBorderWidth: 6,
            data: Object.values(team.monthly_locations_count),
          },
          {
            label: "  Manifestazioni",
            backgroundColor: "#0071BC",
            borderColor: "#0071BC",
            fill: false,
            lineTension: 0,
            borderWidth: 2,
            pointBorderWidth: 6,
            pointHoverBorderWidth: 6,
            data: Object.values(team.monthly_events_count),
          },
        ],
      };
    } else {
      return {
        labels: Object.keys(team.yearly_locations_count),
        datasets: [
          {
            label: "  Associazioni",
            backgroundColor: "#B40000",
            borderColor: "#B40000",
            fill: false,
            lineTension: 0,
            data: Object.values(team.yearly_locations_count),
          },
          {
            label: "  Manifestazioni",
            backgroundColor: "#0071BC",
            borderColor: "#0071BC",
            fill: false,
            lineTension: 0,
            data: Object.values(team.yearly_events_count),
          },
        ],
      };
    }
  }, [team, chartType]);

  if (isFetchingProfile) {
    return (
      <div
        style={{
          display: "flex",
          alignContent: "center",
          justifyContent: "center",
          marginTop: "20%",
        }}
      >
        <Loader type="Oval" color="#B40000" height={100} width={100} />
      </div>
    );
  }

  return (
    <>
      <GoBack />
      <Body>
        <Select
          options={selectOptions}
          placeholder="Seleziona team"
          wrapperStyle={{
            width: "100%",
            backgroundColor: "white",
            marginBottom: 10,
          }}
          onChange={(option) => {
            setSelectedTeam(option.value);
          }}
          value={currentOption}
        />
        {selectedTeam && isFetchingTeam && (
          <div
            style={{
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
              marginTop: "20%",
            }}
          >
            <Loader type="Oval" color="#B40000" height={100} width={100} />
          </div>
        )}
        {selectedTeam && !isFetchingTeam && (
          <>
            <Container
              content={
                <>
                  <Wallpaper />
                  <UserInfoTeam>
                    <IpohWrapper>
                      <IpohText>Creato</IpohText>
                      <IpohTime>
                        {moment(team.created_at)
                          .format("MMM YYYY")
                          .toUpperCase()}
                      </IpohTime>
                    </IpohWrapper>
                  </UserInfoTeam>
                </>
              }
            ></Container>
            <Container
              title={"Situazione Classifiche Team"}
              isTime
              leaderboardType={leaderboardType}
              content={
                <>
                  <GeneralWrapper>
                    <LeaderboardWrapper>
                      <Leaderboard
                        style={{ marginRight: 17, marginLeft: 14.5 }}
                      >
                        <LeaderboardPositionWrapper>
                          <LeaderboardPosition>
                            Pos in classifica{" "}
                            {leaderboardType === "month"
                              ? team.monthly_location_count_rank
                              : team.yearly_location_count_rank}
                            °
                          </LeaderboardPosition>
                        </LeaderboardPositionWrapper>
                        <AssociationsNumber>
                          {leaderboardType === "month"
                            ? team.total_monthly_location_count
                            : team.total_yearly_location_count}
                        </AssociationsNumber>
                        <RegisteredAssociationsWrapper>
                          <ProfilePlus />
                          <RegisteredAssociationsText>
                            Associazioni Registrate
                          </RegisteredAssociationsText>
                        </RegisteredAssociationsWrapper>
                      </Leaderboard>
                      <Leaderboard style={{ marginRight: 15 }}>
                        <LeaderboardPositionWrapper>
                          <LeaderboardPosition>
                            Pos in classifica{" "}
                            {leaderboardType === "month"
                              ? team.monthly_event_count_rank
                              : team.yearly_event_count_rank}
                            °
                          </LeaderboardPosition>
                        </LeaderboardPositionWrapper>
                        <ManifestationsNumber>
                          {leaderboardType === "month"
                            ? team.total_monthly_event_count
                            : team.total_yearly_event_count}
                        </ManifestationsNumber>
                        <RegisteredManifestationsWrapper>
                          <MegaphoneIcon />
                          <RegisteredManifestationsText>
                            Manifestazioni Effettuate
                          </RegisteredManifestationsText>
                        </RegisteredManifestationsWrapper>
                      </Leaderboard>
                    </LeaderboardWrapper>
                    <SwitchWrapper>
                      <SwitchText>MENSILE</SwitchText>
                      <div style={{ marginLeft: 6, marginRight: 6 }}>
                        <Switch
                          checked={leaderboardType === "year"}
                          offColor={"#B40000"}
                          onColor={"#B40000"}
                          height={25}
                          width={43}
                          handleDiameter={25}
                          uncheckedIcon={false}
                          checkedIcon={false}
                          onChange={(type) => setLeaderboardType(type)}
                          activeBoxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        />
                      </div>
                      <SwitchText>ANNUALE</SwitchText>
                    </SwitchWrapper>
                  </GeneralWrapper>
                </>
              }
            />
            <Container
              title={"Membri"}
              content={
                <>
                  {team &&
                    team.users &&
                    team.users.length > 0 &&
                    team.users.map((user, index) => (
                      <React.Fragment key={user.id}>
                        {index > 0 && <div style={{ height: '1px', maxWidth: '320px', backgroundColor: '#dfe3e7', margin: 'auto' }} />}
                        <UserRowContainer
                          onClick={() => {
                            if (profile?.user?.role === 'manager' || profile?.user?.id === user.id) {
                              visualizeOne(user.id)
                            }
                          }}
                          style={{
                            cursor: profile?.user?.role === 'manager' || profile?.user?.id === user.id ? "pointer" : "default",
                            
                          }}
                        >
                          <UserRow>
                            <ProfilePictureAndName>
                              <div
                                style={{
                                  width: 35,
                                  height: 35,
                                  borderRadius: 100,
                                  borderWidth: 2,
                                  borderStyle: "solid",
                                  borderColor: "#b40000",
                                  backgroundImage: `url(${
                                    "https://admin.ipoh.app/" +
                                    user.user_pic_url
                                  })`,
                                  backgroundSize: "cover",
                                }}
                              />
                              <UsernameMember>{user.name}</UsernameMember>
                            </ProfilePictureAndName>
                            <NumberIcons>
                              {(profile?.user?.role === "manager" || profile?.user?.id === user.id) && (
                                <IconColumn>
                                  <ProfilePlusLittle />
                                  <Number>{user.total_location_count}</Number>
                                </IconColumn>
                              )}
                              {(profile?.user?.role === "manager" || profile?.user?.id === user.id) && (
                                <IconColumn>
                                  <MegaphoneIconLittle />
                                  <Number>{user.total_event_count}</Number>
                                </IconColumn>
                              )}
                            </NumberIcons>
                          </UserRow>
                        </UserRowContainer>
                      </React.Fragment>
                    ))}
                </>
              }
            />
            <Container
              title={"Statistiche Team"}
              isStats
              type={chartType}
              setType={(type) => setChartType(type)}
              content={
                <Chart
                  options={{
                    data: chartData,
                  }}
                />
              }
            ></Container>
          </>
        )}
      </Body>
    </>
  );
}

export default Teams;
