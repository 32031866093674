import React from "react";
import styled from "styled-components";
import { ReactComponent as Point } from "../../assets/medal.svg";
import { ReactComponent as NoPoint } from "../../assets/no-medal.svg";
import { ReactComponent as GreenArrow } from "../../assets/arrow-up-green.svg";
import { ReactComponent as RedArrow } from "../../assets/arrow-down-red.svg";
import { ReactComponent as PlusGuy } from "../../assets/profile-plus.svg";
import { ReactComponent as Megaphone } from "../../assets/megaphone.svg";
import { ReactComponent as WhiteArrow } from "../../assets/arrow-up-white.svg";
import { ReactComponent as TeamworkWallp } from "../../assets/teamwork-wallp.svg";
import { ReactComponent as Divisor } from "../../assets/divisor.svg";
import "../../app.css";

export const NextDivisor = () => {
  return <Divisor width={"100%"} height={1} />;
};

export const Medal = () => {
  return (
    <div style={{ marginRight: 5 }}>
      <Point width={12} height={20} />
    </div>
  );
};

export const Wallpaper = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: 10,
      }}
    >
      <TeamworkWallp width={320} height={104} />
    </div>
  );
};

export const MegaphoneIcon = () => {
  return <Megaphone width={25} height={20} />;
};

export const MegaphoneIconLittle = () => {
  return <Megaphone width={15} height={15} />;
};

export const UpArrow = () => {
  return <GreenArrow width={7} height={7} />;
};

export const DownArrow = () => {
  return <RedArrow width={7} height={7} />;
};

export const HeadArrow = () => {
  return <WhiteArrow width={20} height={11} />;
};

export const ProfilePlus = () => {
  return <PlusGuy width={25} height={25} />;
};

export const ProfilePlusLittle = () => {
  return <PlusGuy width={15} height={15} />;
};

export const NoMedal = () => {
  return (
    <div style={{ marginRight: 5 }}>
      <NoPoint width={12} height={20} />
    </div>
  );
};

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 18px;
  padding-top: 18px;
  background-color: #f2f3f8;
  align-items: center;
`;

export const LoadFile = styled.input.attrs((props) => ({
  type: "file",
}))`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;

export const UserWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 35px;
`;

export const UserRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
export const UserRowContainer = styled.div`
  width: 90%;
  margin: 12px auto;

  @media (min-width: 768px) {
    width: 70%;
  }
`;

export const ProfilePictureAndName = styled.div`
  display: flex;
  gap: 10px;
  flex-grow: 1;
  align-items: center;
`;
export const NumberIcons = styled.div`
  display: flex;
  gap: 30px;
  flex-shrink: 0;
`;

export const Popup = styled.div`
  position: absolute;
  margin-top: 60%;
  padding: 40px 10px;
  width: 85%;
  z-index: 1000;
  height: auto;
  background-color: white;
  border-radius: 18px;
  margin-bottom: 18px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 10px 10px;
  @media (min-width: 768px) {
    margin-top: 20%;
    width: 30%;
  }
`;

export const PopupTextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PopupButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  bottom: -70px;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 500;
`;

export const BlackText = styled.p`
  font-size: 14px;
  margin-bottom: 4px;
  color: black;
`;

export const GradientWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-bottom: 24.6px;
`;

export const UsernameMember = styled.p`
  font-size: 17px;
  color: black;
  width: 50%;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const Username = styled.p`
  font-size: 16px;
  color: black;
  margin-top: 21px;
`;

export const Role = styled.p`
  font-size: 12px;
  color: #808080;
  margin-top: 3px;
`;

export const Number = styled.p`
  font-size: 17px;
  color: #808080;
`;

export const UserInfo = styled.div`
  display: flex;
  width: 80%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 33px auto 35px;
`;

export const UserInfoTeam = styled.div`
  display: flex;
  width: 85%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 33px auto 35px;
`;

export const TeamWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const TeamText = styled.p`
  font-size: 12px;
  color: black;
`;

export const TeamName = styled.p`
  font-size: 12px;
  color: #808080;
  margin-top: 4px;
`;

export const IpohWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const IconColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
`;

export const IpohText = styled.p`
  font-size: 12px;
  color: black;
`;

export const IpohTime = styled.p`
  font-size: 12px;
  color: #808080;
  margin-top: 4px;
`;

export const MedalsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
`;
export const MedalsCount = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 3px;
`;

export const Level = styled.p`
  font-size: 12px;
  color: #808080;
`;

export const GeneralWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 13px;
`;

export const RewardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;
`;

export const LeaderboardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 15px;
`;

export const Leaderboard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border-style: solid;
  border-width: 0.25px;
  border-color: #cccccc;
  padding: 17px 0;
  @media (min-width: 768px) {
    padding: 17px 10px;
    width: 30%;
  }
`;

export const LeaderboardPosition = styled.p`
  font-size: 12px;
  color: #808080;
`;

export const AssociationsNumber = styled.p`
  font-size: 54px;
  color: black;
  margin-top: 9px;
  margin-bottom: 9px;
`;

export const ManifestationsNumber = styled.p`
  font-size: 54px;
  color: black;
  margin-top: 9px;
  margin-bottom: 9px;
`;

export const LeaderboardPositionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const RegisteredAssociationsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: 9px;
`;

export const RegisteredManifestationsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: 9px;
`;

export const Title = styled.p`
  font-size: 12px;
  margin-left: 10px;
  color: black;
`;

export const RegisteredAssociationsText = styled.p`
  font-size: 12px;
  color: black;
  text-align: center;
  @media (min-width: 768px) {
    margin-left: 5px;
  }
`;

export const RegisteredManifestationsText = styled.p`
  font-size: 12px;
  color: black;
  text-align: center;
  @media (min-width: 768px) {
    margin-left: 8px;
  }
`;

export const SwitchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 18px;
  margin-bottom: 31px;
`;

export const SwitchText = styled.p`
  font-size: 12px;
  color: #808080;
`;

export const ExpBarWrapper = styled.div`
  width: 95%;
  padding-left: 18px;
  margin-bottom: 25px;
`;

export const ExpTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 18px;
  padding-right: 16px;
  margin-bottom: 3px;
`;

export const ExpText = styled.p`
  font-size: 12px;
  color: #808080;
`;

export const ShowAllWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 25.5px;
`;

export const FixedButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 46px;
  height: 46px;
  background-color: #b40000;
  border-radius: 100px;
  bottom: 49.2px;
  right: 24.6px;
  z-index: 100;
  cursor: pointer;
`;

export const IconWrapper = styled.div`
  margin-left: 7px;
  margin-right: 7px;
`;
